@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	position: relative;
	transition-property: opacity, background, filter;
	transition-duration: 500ms;
	transition-timing-function: ease-in-out;
}

.page-transition {
	@apply opacity-0 bg-eerieBlack;
	filter: blur(12px);
}

.section-loader {
	@apply fixed top-0 left-0 w-full h-full bg-eerieBlack;
}

.spinner {
	z-index: 5000;
	width: 100px;
	aspect-ratio: 1;
	padding: 10px;
	box-sizing: border-box;
	display: grid;
	background: #fff;
	filter: blur(5px) contrast(10) hue-rotate(300deg);
	mix-blend-mode: darken;
}
.spinner:before,
.spinner:after {
	content: '';
	grid-area: 1/1;
	width: 40px;
	height: 40px;
	background: #e8a45c;
	animation: l7 2s infinite;
}
.spinner:after {
	animation-delay: -1s;
}

.curve {
	@apply relative z-[50];
}

.pointer-overlay {
	@apply fixed inset-0 z-[100];
	pointer-events: all;
}

.curve .svg {
	@apply z-[55] fixed h-[calc(100vh+300px)] w-screen pointer-events-none left-0 top-0;
}

.curve .route-container {
	@apply z-[65] fixed top-0 left-0 w-screen h-screen pointer-events-none;
}

.curve .route {
	@apply absolute left-1/2 top-[40%] text-[32px] text-white -translate-x-1/2  text-center font-jakarta text-nowrap;
	@apply sm:text-[40px];
	@apply md:text-[46px];
	@apply lg:text-[50px];

	will-change: top, opacity !important;
}

.pointer-overlay {
	@apply fixed inset-0 z-[100];
	pointer-events: all;
}

* {
	scrollbar-width: none;
}

::-webkit-scrollbar {
	display: none;
}

html {
	overflow: -moz-scrollbars-none;
}

body {
	scrollbar-width: none;
}

@keyframes l7 {
	0% {
		transform: translate(0, 0);
	}
	25% {
		transform: translate(100%, 0);
	}
	50% {
		transform: translate(100%, 100%);
	}
	75% {
		transform: translate(0, 100%);
	}
	100% {
		transform: translate(0, 0);
	}
}

:root {
	--eerie-black: 27, 27, 27;
	--eerie-black-800: 0deg 0% 11%;
	--eerie-black-light: 37, 37, 37;
	--eerie-black-light-hover: 45, 45, 45;

	--white: 255, 255, 255;
	--white-smoke: 244, 244, 244;
	--white-900: 255, 255, 255, 0.9;
	--white-800: 255, 255, 255, 0.8;
	--white-600: 255, 255, 255, 0.6;
	--white-400: 255, 255, 255, 0.4;
	--white-118: 255, 255, 255, 0.118;
	--white-100: 255, 255, 255, 0.1;
	--white-50: 255, 255, 255, 0.05;
	--white-20: 255, 255, 255, 0.02;
	--primary: 232, 164, 92;
	--primary-middle: 234, 185, 122;
	--primary-light: 237, 206, 152;
	--primary-palid: 240, 226, 182;

	--border: 0 0% 89.8%;
}
